@import 'variables';

/* latin-ext */
@font-face {
    font-family: 'Andika';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/andika-latin-ext.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Andika';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/andika-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/archivo-latin-ext.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/archivo-latin.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 800;
    font-stretch: 100%;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/archivo-latin-ex-800.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 800;
    font-stretch: 100%;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/archivo-latin-800.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Paytone One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/paytone-one-latin-ex.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
        U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Paytone One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(Pages/games/pwa/bacanagol/fonts/paytone-one-latin.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    font-family: 'Andika', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    color: $main-text;

    @media (min-width: $mobile-lg) {
        font-size: 24px;
    }
}

h1 {
    font-family: 'Paytone One', sans-serif;
    font-size: 20px;
    line-height: 100%;

    @media (min-width: $mobile) {
        font-size: 24px;
    }
    @media (min-width: $mobile-lg) {
        font-size: 24px;
    }
    @media (min-width: $tablet-sm) {
        font-size: 36px;
    }
    @media (min-width: $desktop-sm) {
        font-size: 40px;
    }
    @media (min-width: $desktop-lg) {
        font-size: 54px;
    }
}

h2 {
    font-family: 'Paytone One', sans-serif;
    font-size: 30px;

    @media (min-width: $mobile-lg) {
        font-size: 40px;
    }
    @media (min-width: $desktop-lg) {
        font-size: 48px;
    }
}

h3 {
    font-family: 'Paytone One', sans-serif;
    font-size: 24px;
}

h4 {
    font-family: 'Paytone One', sans-serif;
    font-size: 24px;
}

h5 {
    font-family: 'Paytone One', sans-serif;
}
